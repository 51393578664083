<template>
  <loading :active="isLoading" :is-full-page="fullPage" :loader="loader" />
  <div class="min-h-screen w-full bg-teal-900 px-6">
    <AdminHeader />

    <div class="flex justify-center w-full">
      <div class="w-full max-w-4xl px-4 md:px-8 lg:px-16 pb-8">
        <!-- Title with dot -->
        <div class="text-white text-2xl flex items-baseline mb-8">
          Message
          <span class="ml-1 text-4xl leading-none" style="color: #00A388"
            >.</span
          >
        </div>

        <!-- Message Form -->
        <div class="space-y-6">
          <!-- Message Input -->
          <div>
            <div class="text-white text-md mb-2 text-left ">Message</div>
            <textarea
              v-model="message"
              class="w-full bg-white rounded p-4 text-sm"
              placeholder="Message"
              style="min-height: 190px;"
            ></textarea>
          </div>

          <!-- Send to Section -->
          <div class="md:w-3/5">
            <div class="text-white text-md mb-2 text-left">Send to</div>
            <div class="flex gap-4 mb-4">
              <button
                class="bg-white rounded-xl w-full py-4 flex items-center gap-2 px-4"
                @click="selectedRecipient = 'vendors'"
              >
                <div
                  class="w-4 h-4 rounded-full"
                  :class="
                    selectedRecipient === 'vendors'
                      ? 'border-4 border-emerald-500'
                      : 'bg-gray-300'
                  "
                ></div>
                <span class="text-gray-700">Vendors</span>
              </button>
              <button
                class="bg-white rounded-xl py-4 flex w-full items-center gap-2 px-4"
                @click="selectedRecipient = 'users'"
              >
                <div
                  class="w-4 h-4 rounded-full"
                  :class="
                    selectedRecipient === 'users'
                      ? 'border-4 border-emerald-500'
                      : 'bg-gray-300'
                  "
                ></div>
                <span class="text-gray-700">Users</span>
              </button>
            </div>

            <!-- Horizontal Line -->
            <div class="border-b border-gray-600 my-6"></div>

            <!-- Search Filters -->
            <div class="flex gap-4 mb-8 flex-1/2">
              <div class="relative flex-1">
                <select
                  class="w-full py-4 px-3 rounded-xl  appearance-none focus:outline-none"
                  v-model="selectedState"
                  @change="debouncedSearch"
                >
                  <option value="" disabled selected>State</option>
                  <option
                    v-for="state in states"
                    :key="state.id"
                    :value="state.id"
                  >
                    {{ state.name }}
                  </option>
                </select>
                <div
                  class="absolute right-2 mr-1 top-1/2 transform flex -translate-y-1/2 pointer-events-none"
                >
                  <span
                    style="background-color:#AFBDC1 ;color:#06262D"
                    class="material-icons arrow-down-icon justify-center items-center"
                  >
                    arrow_drop_down
                  </span>
                </div>
              </div>
              <div class="relative flex-1 hidden">
                <select
                  class="w-full py-4 px-3 rounded-xl bg-white appearance-none focus:outline-none"
                  v-model="selectedIndustry"
                  @change="debouncedSearch"
                >
                  <option value="" disabled selected>Industry</option>

                  <option
                    v-for="industry in industries"
                    :key="industry.code"
                    :value="industry.code"
                  >
                    {{ industry.name }}
                  </option>
                </select>
                <div
                  class="absolute right-2 mr-1 top-1/2 transform flex -translate-y-1/2 pointer-events-none"
                >
                  <span
                    style="background-color:#AFBDC1 ;color:#06262D"
                    class="material-icons arrow-down-icon justify-center items-center"
                  >
                    arrow_drop_down
                  </span>
                </div>
              </div>
            </div>
          </div>

          <!-- Send Button -->
          <button
            @click="sendMessage"
            class="flex
            bg-teal-500 text-white mb-8 px-8 py-3 rounded-md"
            style="background-color: #00A388"
          >
            Send
          </button>

          <!-- Back Button -->
          <button
            @click="$router.back()"
            class="bg-teal-800 gap-5 text-white px-6 py-4 rounded-md flex items-center space-x-2 mt-12"
            style="background-color: #204952"
          >
            <span class="material-icons text-xl arrow-left-icon"
              >arrow_left</span
            >
            <span>Back</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AdminHeader from "../../components/AdminHeader.vue";
import Loading from "vue-loading-overlay";

export default {
  name: "Message",
  components: {
    AdminHeader,
    Loading,
  },
  data() {
    return {
      isLoading: false,
      fullPage: true,
      loader: "bars",
      message: "",
      selectedRecipient: "vendors",
      selectedState: "",
      selectedIndustry: "",
      states: [],
      industries: [
        { code: "tech", name: "Technology" },
        { code: "health", name: "Healthcare" },
        // Add other industries...
      ],
    };
  },
  methods: {
    async sendMessage() {
      try {
        this.isLoading = true;
        const payload = {
          // customer_of: this.selectedRecipient,
          customer_of:
            this.selectedRecipient === "vendors" ? "vendor" : "customer",
          state_id: this.selectedState,
          message: this.message,
        };

        const response = await this.$http.post(
          "/api/v1/message/customsendpush",
          payload
        );

        if (response.data.status) {
          // Add success notification here if needed
          this.message = "";
          console.log(response.data.message);
          this.$swal.fire({
            icon: "success",
            title: response.data.message,
            timer: 3000,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
          });
          // this.$router.push("/admin/dashboard"); // Or wherever you want to redirect
        }
      } catch (error) {
        console.error(
          "Error sending message:",
          error.response?.data?.message || error.message
        );
        this.$swal.fire({
          icon: "error",
          title: error.response?.data?.message || "Failed to update vendor",
          toast: true,
          position: "top-end",
          timer: 5000,
          showConfirmButton: false,
        });
        // Add error notification here if needed
      } finally {
        this.isLoading = false;
      }
    },
    async fetchStates() {
      try {
        this.isLoading = true;
        const response = await this.$http.get("/api/v1/auth/states"); // Replace with your actual API URL
        const data = response.data;
        if (data.status) {
          this.states = data.data; // Populate states from the API response
        } else {
          console.error(data.message);
        }
      } catch (error) {
        console.error("Error fetching states:", error);
      } finally {
        this.isLoading = false;
      }
    },
  },
  created() {
    this.fetchStates(); // Fetch states when the component is created
  },
};
</script>

<style scoped>
@import "../../assets/css/dashboard.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");

.bg-teal-900 {
  background-color: #06262d;
}

select,
textarea {
  color: #707070;
}

select::placeholder,
textarea::placeholder {
  color: #707070;
}

.bg-teal-500 {
  background-color: #00a388;
}

.arrow-left-icon {
  color: #00a388;
  background-color: #002f28;
  display: inline-flex;
  border-radius: 4px;
}

.arrow-down-icon {
  font-size: 24px;
  color: #6b7280;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select:focus {
  outline: none;
  border: 1px solid #00a388;
}

.border-emerald-500 {
  border-color: #00a388;
}

.bg-gray-300 {
  background-color: #d1d5db;
}
</style>
